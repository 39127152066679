<template>
  <page-layout>
    <span slot="header-name">{{ $tf(create ? 'create | camera_group,,1' : 'edit | camera_group,,1') }}</span>
    <common-tabs slot="header-actions" v-model="tab" :items="tabs" :converter="$tfo" class="camera-group-tabs"></common-tabs>
    <div slot="content">
      <el-form
        v-show="tab === 'info'"
        v-loading="loading"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        ref="form"
        :name="$options.name"
        :model="item"
        :rules="rules"
      >
        <el-form-item :label="$tf('common.id')" v-if="item.id">
          <span name="id">{{ item.id }}</span>
        </el-form-item>
        <el-form-item :label="$tf('common.name')" prop="name">
          <el-input name="name" v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item :label="$tf('common.comment')" prop="comment">
          <el-input name="comment" v-model="item.comment"></el-input>
        </el-form-item>

        <el-form-item :label="$tf('common.labels')">
          <el-select v-model="labels" multiple filterable allow-create default-first-option :placeholder="$tf('input_label_names')" class="input--medium">
            <el-option v-for="item in labelItems" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$tfo(['common.deduplicate', 'common.events'])" prop="deduplicate">
          <el-checkbox name="deduplicate" v-model="item.deduplicate"></el-checkbox>
          <div class="parameter-description">{{ $tf('parameter.deduplicate_desc') }}</div>
        </el-form-item>
        <el-form-item :label="$tfo('common.deduplicate_delay')" prop="deduplicateDelay">
          <el-input name="deduplicate-delay" v-model="item.deduplicateDelay"></el-input>
          <div class="parameter-description">{{ $tf('parameter.deduplicate_delay_desc') }}</div>
        </el-form-item>
        <el-form-item :label="$tfo('confidence_threshold')">
          <threshold-selector :item="item" />
          <div class="parameter-description">{{ $tf('threshold_warning') }}</div>
        </el-form-item>
        <el-form-item prop="active">
          <el-checkbox name="active" v-model="item.active">{{ $tf('common.active,,1') }}</el-checkbox>
        </el-form-item>
      </el-form>

      <div v-show="tab === 'permissions'" v-loading="loading">
        <el-table name="permissions-table" class="permissions-table" :size="$vars.sizes.table || $vars.sizes.common" stripe :data="groupItems">
          <el-table-column prop="name" :label="$tf('name')">
            <template slot-scope="{ row }">
              <span>{{ row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="" :align="'center'" :label="$tfo('none')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="''" :value="!item.permissions[row.id] && ''" @change="changePermissionHandler(row, '')" :disabled="isDisabled(row)"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('view')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'view'" :value="item.permissions[row.id]" @change="changePermissionHandler(row, 'view')" :disabled="isDisabled(row)"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('change')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'edit'" :value="item.permissions[row.id]" @change="changePermissionHandler(row, 'edit')" :disabled="isDisabled(row)"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="mar-top--large">
        <el-button
          name="save-btn"
          type="primary"
          @click="saveHandler"
          :disabled="create ? $hasNoPermission('ffsecurity.add_cameragroup') : $hasNoPermission('ffsecurity.change_cameragroup')"
          >{{ $tf('common.save') }}
        </el-button>
        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
      </div>
      <div class="mar-top--large">
        <confirm-button
          target="camera_group"
          size="mini"
          plain
          @confirm="deleteHandler"
          :disabled="$hasNoPermission('ffsecurity.delete_cameragroup')"
          v-if="!create"
        >
        </confirm-button>
      </div>
    </div>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import * as validator from '@/apps/common/validator';
import ConfidenceSelect from '../common/confidence.select';
import ConfirmButton from '../confirm/confirm-button';
import ThresholdSelector from '../common/threshold.selector';
import PageLayout from '@/components/page/layout';

let baseRules = {
  name: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  deduplicateDelay: [
    {
      required: true,
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: validator.getNumberValidator({ required: true, unsigned: true, min: 0, max: 10000 })
    }
  ]
};

export default {
  components: {
    ThresholdSelector,
    ConfidenceSelect,
    ConfirmButton,
    PageLayout
  },
  name: 'camera-group-form',
  data: function () {
    return {
      tab: 'info',
      rules: this.$applyRuleMessages(baseRules),
      item: {},
      labels: [],
      loading: false
    };
  },
  watch: {
    'item.labels': function (v) {
      this.labels = Object.keys(v || {});
    }
  },
  computed: {
    cameraGroupFormTitle() {
      return this.create ? this.$tfo(['common.create', 'common.camera_group,, 1']) : this.$tfo(['common.edit', 'common.camera_group,, 1']);
    },
    tabs() {
      const Tabs = [{ name: 'info' }, { name: 'permissions' }];
      return this.$store.getters.hasAcl ? Tabs : Tabs.slice(0, 1);
    },
    groupItems() {
      return [...this.$store.state.groups.items].sort((a, b) => (a.id === this.userPrimaryGroup ? -100 : a.id - b.id));
    },
    userPrimaryGroup() {
      return this.$store.state.users.current.primary_group;
    },
    labelItems() {
      let labelsObject = (this.$store.state.camera_groups.items || []).reduce((m, v) => Object.assign(m, v.labels), {});
      return Object.keys(labelsObject);
    },
    create() {
      return !this.$route.params.id;
    },
    state() {
      return this.$store.state.camera_groups;
    },
    isVideoArchiveCameraGroupSelected() {
      return this.item && this.item.id === this.state.VideoArchiveCameraGroupId;
    }
  },
  created() {
    let id = this.$route.params.id;
    this.item = _.cloneDeep(this.state.item.empty);

    if (id) {
      this.loading = true;
      this.$store
        .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
        .then((v) => {
          this.item = v;
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$store.dispatch(this.$store.state.camera_groups.Action.Get);
    this.$store.dispatch(this.$store.state.groups.Action.Get);
  },
  methods: {
    isDisabled(row) {
      return this.isVideoArchiveCameraGroupSelected || (this.userPrimaryGroup !== 1 && row.id === this.userPrimaryGroup);
    },
    afterCreate() {
      this.$router.push({ path: '/camera-groups/' + this.item.id + '/' });
    },
    previewFileHandler() {},
    deleteFileHandler() {},
    saveHandler(e) {
      this.$refs.form.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      this.item.labels = (this.labels || []).reduce((m, v) => {
        m[v] = 'true';
        return m;
      }, {});
      this.save(this.item);
    },
    save(updatedItem) {
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, updatedItem)
        .then((v) => {
          this.item.id = v.id;
          this.item.permissions = v.permissions;
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: `/camera-groups/${v.id}/` });
          this.$store.dispatch('syncStateItems', 'camera-groups');
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteHandler(e) {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: '/camera-groups/' });
          this.$store.dispatch('syncStateItems', 'camera-groups');
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    cancelHandler(e) {
      this.$router.backTo({ path: '/camera-groups/' });
    },
    changePermissionHandler(group, v) {
      this.$set(this.item.permissions, group.id, v);
      if (!v) this.item.permissions[group.id] = null;
    }
  }
};
</script>
